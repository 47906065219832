.border-gradient {
    border: 5px solid;
   
  }
  .get-started{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto;
  }
.each-getstarted-card{
    margin: 1rem 1rem;
    --angle: 0deg;
    flex: 1 0 30%;
    border: solid 2px;
border-image-slice: 1;
    border-image-source: linear-gradient(var(--angle),  rgba(241, 223, 125), rgb(85, 74, 13));
    animation: 3s rotate linear infinite;
    height: 12rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 2rem 0;  
    /* box-shadow: 0rem 0rem .7rem  .1rem gold;   */
}  

@keyframes rotate {
    to {
      --angle: 360deg;
    }
  }
  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }

.get-started .each-getstarted-card:nth-of-type(4){
  border-image-source: linear-gradient(var(--angle),   rgba(155, 2, 155, 0.5), rgba(254, 82, 255, 0.5));
}
.get-started .each-getstarted-card:nth-of-type(4){
  border-image-source: linear-gradient(var(--angle),  rgba(126, 34, 206, 0.5), rgba(64, 15, 107, 0.5));
}
.get-started-icon1,.get-started-icon2,.get-started-icon3{
   width: 2rem;
   height: 2rem;
}
.get-started .each-getstarted-card button{
  border: 2px rgba(241, 223, 125) solid;
  background-color: rgba(241,223,125);
 
}
/* .get-started .each-getstarted-card:nth-of-type(2) button{
  border: 2px rgba(155,2,155) solid;
  background-color:  rgba(155,2,155);
}
.get-started .each-getstarted-card:nth-of-type(3) button{
  border: 2px rgba(126,34,206) solid;
  background-color: rgba(126,34,206);
} */

.get-started button{
  border-radius: 0.2rem;
  padding: 0.1rem 0.5rem;
   color: black
}
.get-started button:hover{
  background-color: black !important;
  color: white;
}
.get-started .each-getstarted-card button:hover{
  color: rgba(241,223,125);
}
/* .get-started .each-getstarted-card:nth-of-type(2) button:hover{
  color:  rgba(155,2,155);
}
.get-started .each-getstarted-card:nth-of-type(3) button:hover{
  color: rgba(126,34,206);
} */

.get-started-icon1{
  color: rgba(241, 223, 125);
}
/* .get-started-icon2{
  color: rgba(155, 2, 155);
}
.get-started-icon3{
  color: rgba(126, 34, 206)
} */
.get-about{
    width: 80%;
    height: 3rem;
    margin: auto;
    text-align: center;
}

@media(max-width: 1000px) {
  .get-started{
      flex-direction: column-reverse;
  }
}