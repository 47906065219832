.filters{
    display: flex;
    flex-wrap: wrap;
}
.filter{
   flex: 1 0 25%;
   display: flex;
   flex-direction: column;
 
         margin: 0 1rem;

}
.filter-border{
    display: flex;
    flex-direction: column;     
    height: 15rem;
  justify-content: center;
   align-items: center;
    border-color: rgba(234, 179, 8, .2 );
   border-left: 25px solid transparent;
   border-right: 25px solid transparent;
   border-top: 3px solid #555;
   border-bottom: none;
}
.filter-icon{
    width: 3rem;
    height: 3rem;
    color: #b29758;    
}
 

.filter p:nth-of-type(1) {
    color: gold
        /* background: linear-gradient(
            to right,
            #0190cd 30%,
            #764ada 20%,
            purple 70%,
            rgb(43, 0, 255) 80%
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-fill-color: transparent;
        background-size: 500% auto;
        animation: textShine 5s ease-in-out infinite alternate;*/
    } 
    @keyframes textShine {
        0% {
            background-position: 0% 50%;
        }
        100% {
            background-position: 100% 50%;
        }
}   
.filter p:nth-of-type(2) {
    font-size: small;
}
.filters-container {

}
 
.geeks {
    color: green;
}
 
.text {
    display: inline;
    width: 90%;
}
 
.read-or-hide {
    padding: 1rem;
    border-radius: 1rem;
    color: white;
    cursor: pointer;
    background: linear-gradient(-45deg, gold, rgb(85, 74, 13));
    background-size: 400% 400%;

    animation: gradient 3s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
        color: white;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@media (max-width: 800px) {
    .filters{
        flex-direction: column;
    }
    .filter{
        padding-top: 2rem;
    }
}