.table-container {
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
.api-container{
    margin-top: 5vh;
       margin-bottom: 10vh;

}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

table { 

    border-collapse: collapse;
    border-spacing: 0;
    width: 80%;
    margin: auto;
    border: 1px solid rgb(81, 76, 47);
    text-align: center;
  }

  tbody {
    background-color: #1111;
    border: 1px solid rgb(151, 142, 88);

  }
  th, td {
    text-align: left;
    padding: 8px;
  }
  tr{
    border-top: 0.1px rgb(100, 99, 93) solid;
  }
  
  tr:nth-child(1){
    background-color:  rgb(99, 89, 36);
    color: rgb(232, 230, 215);
    border-bottom: 1px solid rgb(163, 158, 128);
    }

tr:nth-child(even){
    color: rgb(232, 230, 215);
}

.loader-container {
    top: 0;
    bottom: 0;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1111;
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: rgba(241,223,125)  transparent rgba(241,223,125)  transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}
.pagination button{
    border: 2px solid rgba(241,223,125) ;
    margin-right: 2px;
    margin-top: 5px;
    padding: 0 .5rem;
    border-radius: .4rem;
    
}
@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 600px) {
    table{
        width: 100%;
    }
    .table-container{
        overflow-x:auto;
    }
}
    
