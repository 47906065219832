.App {
  text-align: center;
}

h4{
  background-image: linear-gradient(10deg, #cccccc, #eab308);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 3rem;
}

