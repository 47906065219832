
.bg-image{
    filter: blur(20px);
    opacity: 0.1;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  width: 60%;
  height: 100vh;
  z-index: -1;
}
.body-container{
  margin-top: 2rem;
    width: 100%;
}
.gradient-text {
  color: rgba(241,223,125);
    /* background-image: linear-gradient(90deg,rgba(241,223,125), #645142);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent; */
  }
  .greetings p {
    font-size: 2rem;
    z-index: 1000;
  }

  .eth-ic{
    color: rgba(241,223,125) !important;
  }
  
  .greetings-article{
    font-weight: 600;
    margin: auto;
    width: 60%;
  }
.greetings{
  margin-top: 10vh;
  text-align: center;
}
 
  .typewriter{  
    text-align: center;
 
      font-weight: 700;

    font-size: 4rem;
  }

  .eth-container{
    margin-top: 5rem;
  

  }
  .intro-section{
    height: 80vh;
  }
  .eth-container img{   
      animation: float-up-down 2s ease-in-out infinite both;
      margin: auto;
    height: 30vh;
  }
  .eth-container a{
  }
  @keyframes float-up-down {
    0% {
        transform:translateY(0)
    }

    50% {
        transform: translateY(-10%);
        transform:translateY(-10%)
    }

    100% {
        transform: translateY(0);
        transform:translateY(0)
    }
}
  @media(max-width: 1000px) {
    
   .bg-image{
    width: 100%;
   }
  }
  @media (max-width: 500px) {
    .greetings-article{
      width: 80%;
      margin: auto;
      font-size: .7rem;
    }
  }
